import React from "react"
import Layout from "../components/layout"

import Sezione from "../components/sezione"
import SEO from "../components/seo"
import CardPagina from "../components/cardPagina"
import BanneronePage from "../components/banneronePage"
import TitoloPagina from "../components/titoloPagina"
import BottoneBianco from "../components/bottoneBianco"
import {Link} from "gatsby"

import {
  Container,
  Row,
  Col,
  Card,
  Badge,
  Tab,
  ListGroup,
  Tabs,
} from "react-bootstrap"

const EternitFotovoltaico = () => (
  <Layout isPagina={true}>
    <SEO
      title="Incentivi, agevolazioni per sostituzione e smaltimento Eternit e fotovoltaico aziendale nel Lazio: Roma, Pomezia, Aprilia, Latina, Anzio, Nettuno"
      description="Rimuovere l'Eternit Conviene! Incentivi e agevolazioni per la sostituzione o lo smaltimento dell'Eternit e la realizzazione di fotovoltaico Aziendale in tutto il Lazio"
    />
    <BanneronePage title="Eternit e Fotovoltaico" />
    <Container className="mt-4 mb-4">
      <TitoloPagina title="Incentivi, agevolazioni per sostituzione e smaltimento Eternit e fotovoltaico aziendale nel Lazio: Roma, Pomezia, Aprilia, Latina, Anzio, Nettuno" />
      <Sezione title="Occasione Unica">
        <CardPagina variante="bordoTop bordoBottom">
          <p>
            Realizzare un impianto fotovoltaico in sostituzione di una copertura
            in eternit, porta ad interessanti benefici economici, in quanto ai
            vantaggi derivanti dall'impianto fotovoltaico: detrazione IRPEF del
            50% (privati) dell'intero costo dell'impianto + autoconsumo +
            Scambio sul Posto, si somma anche la detrazione IRPEF del 50% del
            costo complessivo per la rimozione eternit e rifacimento copertura,
            che se effettuata nei limiti previsti per la trasmittanza termica, è
            incrementata al 65%!! Nonsoloenergia srl si occupa dell'intero iter:
            dall'autorizzazione all'ASL, alla rimozione/smaltimento dell'eternit
            (mediante ditta partner qualificata), al rifacimento della nuova
            copertura in legno o lamiera grecata, per terminare con la
            realizzazione dell'impainto fotovoltaico e relativo iter ncessario
            al riconoscimento degli incentivi dello Scambio sul Posto e delle
            agevolazioni fiscali.
          </p>
          <Row>
            <Col sm={4}>
              <img
                className="rounded mt-1 img-fluid "
                src="../eternit/eternit-e-fotovoltaico-roma.jpg"
                alt="Eternit e Fotovoltaico Anzio Nettuno Aprilia Roma"
              />
            </Col>
            <Col sm={4}>
              <img
                className="rounded mt-1 img-fluid "
                src="../eternit/eternit-e-fotovoltaico-anzio.jpg"
                alt="Eternit e Fotovoltaico Anzio Nettuno Aprilia Roma"
              />
            </Col>
            <Col sm={4}>
              <img
                className="rounded mt-1 img-fluid "
                src="../eternit/eternit-e-fotovoltaico-aprilia.jpg"
                alt="Eternit e Fotovoltaico Anzio Nettuno Aprilia Roma"
              />
            </Col>
          </Row>
          <p>
            <b>Le Aziende </b>possono beneficiare di un credito d’imposta pari
            al 50% della spesa sostenuta ripartita in soli tre anni, anche i
            soggetti titolari di reddito d’impresa, indipendentemente dalla loro
            natura giuridica e dalle dimensioni aziendali, che effettuano
            interventi di bonifica dall’amianto su beni e strutture.
          </p>
          <p>
            <b>Il credito d'imposta </b>(o detrazione fiscale che dir si voglia)
            <b>sarà ripartito su base triennale</b> in quote uguali tra loro
            attraverso la dichiarazione dei redditi a partire dall’anno
            successivo a quello in cui sono stati eseguiti i lavori di bonifica.
          </p>
          <p>
            Le opere per cui è ammessa la detrazione fiscale sono tutte quelle
            volte alla rimozione e smaltimento (anche previo trattamenti in
            impianti autorizzati e specifici del settore) dell’amianto presente
            in coperture in lastre, nelle canalizzazioni, tubi, canne fumarie e
            contenitori per il trasporto e lo stoccaggio di materiali ed anche
            le spese dovute per eventuali perizie di carattere ambientale nel
            limite del 10% della spesa complessiva e comunque per un importo non
            superiore ad € 10.000,00.
          </p>
          <br />
          <p>
            Gli ingegneri presenti nel nostro organico potranno seguire i vari
            progetti dell'intera opera, nelle province del Lazio ed in
            particolare in quelle di Roma e Latina.
          </p>
          <p>
            Contattaci per un sopralluogo in situ per il successivo preventivo
            totalmente gratuito con relativo prospetto del ritorno economico:
            <br />{" "}
            <em className="text-center">
              <strong>
                il costo del rifacimento della copertura e dell'impianto
                fotovoltaico si autofinanzia in pochi anni!
              </strong>
            </em>
          </p>
          <Col xs={12} className="text-center">
            <BottoneBianco
              className="mt-3 mx-auto text-center variantePage"
              title="Richiedi un preventivo"
              link="richiesta-preventivo"
            />
          </Col>
        </CardPagina>
        <Sezione>
          <CardPagina>
            <em>
              <p>
                L'eternit, o cemento amianto, è stato largamente impiegato negli
                ultimi decenni per le più svariate applicazioni. Le coperture
                dei tetti, è stato sicuramente il campo in cui più è stato
                utilizzato, soprattutto per le quantità. Quando se ne scoprì la
                pericolosità, ormai era troppo tardi sia per le enormi quantità
                immesse sul mercato che per i danni alle persone che operavano
                soprattutto nella fabbricazione e nella posa di questi
                manufatti. Il pericolo amianto derivante dallo sfaldamento dell'
                eternit e quindi dalla sua inalazione, prese ben presto forma,
                evidenziando numerose morti per asbestosi. Nonostante i numerosi
                anni da quando l' eternit è stato dismesso, le morti per
                asbestosi sono oggi ancora in crescita, in quanto si ci ammala
                anche a distanza di 30 anni da quando è stato inalato.
              </p>
              <p>
                Se la copertura della vostra abitazione o azienda è ancora in
                eternit, anche se la legge non impone la sua rimozione, se ne
                consiglia comunque un intervento di bonifica, se possibile con
                la sostituzione di questo materiale killer con uno nuovo. Per
                eseguire questo intervento di rimozione, si consiglia inoltre,
                di appoggiarsi ad aziende abilitate ad effettuare questo tipo di
                intervento che sapranno come intervenire per evitare le
                inalazioni mortali.
              </p>
              <strong>
                NonsoloEnergia srl si occupa della rimozione eternit e del suo
                smaltimento nelle discariche autorizzate a prezzi competitivi.
              </strong>
              <br />
              <p>
                Può richiederci un{" "}
                <Link className="testo-verde" to="richiesta-preventivo">
                  <b>preventivo gratuito</b>
                </Link>{" "}
                compilando il form alla richiesta di preventivo, specificando
                nel campo note rimozione eternit ed i mq totali.
              </p>
            </em>
          </CardPagina>
        </Sezione>
      </Sezione>
    </Container>
  </Layout>
)

export default EternitFotovoltaico
